import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Meta from "../components/Meta";
import Footer from "../components/Footer";
import "../css/tech.css";
import { Icon } from "@iconify/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";

const Tech = () => {
  const featuresData = [
    {
      key: "performanceMetrics",
      heading: "Performance Metrics",
      description:
        "With Amigo, you can monitor the real-time performance metrics such as speed, distance and cadence. It becomes easy to do live tracking with your e-bike.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "adaptiveRidingModes",
      heading: "Adaptive Riding Modes",
      description:
        "You can choose between different riding modes that will enable different speeds and adjust to strong throttle effects.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "bikeDiagnostics",
      heading: "Bike Diagnostics",
      description:
        "Data-driven insights to proactively identify maintenance needs, extending your e-bike's lifespan.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "proactiveMaintenance",
      heading: "Proactive Maintenance",
      description:
        "Timely service reminders ensure your e-bike always performs at its best, effortlessly keeping you on top of maintenance.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "customizationsB2B",
      heading: "Customizations for B2B Clients",
      description:
        "Timely service reminders ensure your e-bike always performs at its best, effortlessly keeping you on top of maintenance.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "convenienceConnectivity",
      heading: "Convenience and Connectivity",
      description:
        "Amigo is integrated to support bike dynamics, maximum torque and speed to customise the riding mode to your exact preference.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "fitnessMode",
      heading: "Fitness Mode",
      description:
        "Switch to fitness mode to burn calories faster and amp up your progress by maximising the calorie burn. Push your limits with increased resistance and tailored assistance levels. Check your bike's battery percentage, estimated range and tyre pressure for long trips to ensure your safety and optimise your ride.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "sosFallDetection",
      heading: "SOS Fall Detection",
      description:
        "The app activates SOS through fall detection and initiates a call to your emergency contact after a countdown timer has been triggered.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "childMode",
      heading: "Child Mode",
      description:
        "Activate the safety feature by activating the child mode to set speed limits for your child's safety when using the e-bike.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "completeBikeDiagnostics",
      heading: "Complete Bike Diagnostics",
      description:
        "Prioritise safety with Amigo by running complete bike diagnostics before your ride to ensure everything is in optimal condition.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "antiTheftMode",
      heading: "Anti-Theft Mode",
      description:
        "In the unfortunate event of theft, Amigo immediately alerts you with real-time alerts and provides an option to track your bike, increasing the chances of recovery.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "automaticProximityUnlock",
      heading: "Automatic Proximity Unlock Feature",
      description:
        "The automatic proximity unlock feature allows your smart lock to open as you approach your e-bike, eliminating the need for manual unlocking.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
    {
      key: "diagnosticAlerts",
      heading: "Diagnostic Alerts",
      description:
        "Automatically detect and alert the rider to any faults or issues within the e-bike’s systems, such as motor malfunctions, battery issues or sensor failures. Amigo also provides reminders for regular maintenance tasks like brake pad replacements and tyre checks.",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/feature1/amigoFeature1.png",
    },
  ];
  const [activeFeature1, setActiveFeature1] = useState("performanceMetrics");
  const handleFeature1Click = (feature) => {
    setActiveFeature1(feature.key);
  };
  const getActiveFeature1 = () => {
    return featuresData.find((feature) => feature.key === activeFeature1);
  };
  const activeFeatureObj1 = getActiveFeature1();

  const [activeFeature2, setActiveFeature2] = useState("fitnessMode");
  const handleFeature2Click = (feature) => {
    setActiveFeature2(feature.key);
  };
  const getActiveFeature2 = () => {
    return featuresData.find((feature) => feature.key === activeFeature2);
  };
  const activeFeatureObj2 = getActiveFeature2();
  return (
    <>
      <Meta cannonicalTag={"https://dynem.in/tech"} />
      <Navbar />
      {/* hero banner */}
      <div className="mb-5">
        <picture className="">
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/Amigo+banner.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/Amigo+banner.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/tech/Amigo+banner.jpg"
            alt="amigo"
            className="d-block img-fluid"
          />
        </picture>
      </div>
      <div className="container">
        <div className="text-center mb-5 d-none d-lg-block">
          <p className="m-0 key-points">
            Introducing DYNEM’s proprietary technology via the revolutionary
            Amigo app.
          </p>
          <p className="m-0 key-points">
            With its innovative features and user-friendly interface, Amigo is
          </p>
          <p className="mb-4 key-points">
            set to enhance the riding experience in an interactive way.
          </p>
        </div>
        <div className="text-center mb-5 d-lg-none">
          <p className="m-0 key-points">
            Introducing DYNEM’s proprietary technology via the revolutionary
            Amigo app. With its innovative features and user-friendly interface,
            Amigo is set to enhance the riding experience in an interactive way.
          </p>
        </div>
        {/* feature buttons */}
        <div className="d-lg-none">
          <Splide
            className="mb-5 p-0 align-items-center"
            options={{
              drag: true,
              perPage: 1.2,
              arrows: false,
              pagination: false,
              autoplay: false,
              perMove: 1,
              interval: 4000,
              gap: "0.5em",
              padding: "0em",
              pauseOnHover: false,
              resetProgress: false,
              focus: 0,
              start: 0,
              speed: 500,
            }}
          >
            {featuresData.slice(0, 4).map((feature) => (
              <SplideSlide className="feature-btn-width">
                <button
                  key={feature}
                  className={
                    feature.key === activeFeature1 ? "em-btn-1" : "em-btn-2"
                  }
                  onClick={() => handleFeature1Click(feature)}
                >
                  {feature.heading}
                </button>
              </SplideSlide>
            ))}
          </Splide>
        </div>
        <div className="mb-5 d-none d-lg-block">
          <div
            className="d-flex justify-content-center mb-3"
            style={{ gap: "1.5em" }}
          >
            {featuresData.slice(0, 4).map((feature) => (
              <button
                key={feature}
                className={
                  feature.key === activeFeature1 ? "em-btn-1" : "em-btn-2"
                }
                onClick={() => handleFeature1Click(feature)}
              >
                {feature.heading}
              </button>
            ))}
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ gap: "1.5em" }}
          >
            {featuresData.slice(4, 6).map((feature) => (
              <button
                key={feature}
                className={
                  feature.key === activeFeature1 ? "em-btn-1" : "em-btn-2"
                }
                onClick={() => handleFeature1Click(feature)}
              >
                {feature.heading}
              </button>
            ))}
          </div>
        </div>
        {/* screens */}
        <div
          className="mx-auto d-none d-lg-block my-5"
          style={{ width: "60%" }}
        >
          <div className="d-flex">
            <div className="feature-text w-100">
              <h1>{activeFeatureObj1.heading}</h1>{" "}
              <p>{activeFeatureObj1.description}</p>
            </div>
            <div className="w-100">
              <img
                src={activeFeatureObj1.image}
                alt="amigo-feature"
                className="img-fluid"
              ></img>
            </div>
          </div>
        </div>
        <div className="row my-5 d-lg-none">
          <div className="col-12 col-lg-6 feature-text">
            <h1>{activeFeatureObj1.heading}</h1>{" "}
            <p>{activeFeatureObj1.description}</p>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-flex d-lg-block justify-content-center">
              <img
                src={activeFeatureObj1.image}
                alt="amigo-feature"
                className="img-fluid w-75 mx-auto"
              ></img>
            </div>
          </div>
        </div>

        {/* feature buttons */}
        {/* feature buttons */}
        <div className="text-center my-5">
          <h2>Premium Features of Amigo App</h2>
          <h1>For Customers</h1>
        </div>
        <div className="d-lg-none">
          <Splide
            className="mb-5 p-0 align-items-center"
            options={{
              drag: true,
              perPage: 1.2,
              arrows: false,
              pagination: false,
              autoplay: false,
              perMove: 1,
              interval: 4000,
              gap: "0.5em",
              padding: "0em",
              pauseOnHover: false,
              resetProgress: false,
              focus: 0,
              start: 0,
              speed: 500,
            }}
          >
            {featuresData.slice(6, 10).map((feature) => (
              <SplideSlide className="feature-btn-width">
                <button
                  key={feature}
                  className={
                    feature.key === activeFeature2 ? "em-btn-1" : "em-btn-2"
                  }
                  onClick={() => handleFeature2Click(feature)}
                >
                  {feature.heading}
                </button>
              </SplideSlide>
            ))}
          </Splide>
        </div>
        <div className="mb-5 d-none d-lg-block">
          <div
            className="d-flex justify-content-center mb-3"
            style={{ gap: "1.5em" }}
          >
            {featuresData.slice(6, 10).map((feature) => (
              <button
                key={feature}
                className={
                  feature.key === activeFeature2 ? "em-btn-1" : "em-btn-2"
                }
                onClick={() => handleFeature2Click(feature)}
              >
                {feature.heading}
              </button>
            ))}
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ gap: "1.5em" }}
          >
            {featuresData.slice(10, 13).map((feature) => (
              <button
                key={feature}
                className={
                  feature.key === activeFeature2 ? "em-btn-1" : "em-btn-2"
                }
                onClick={() => handleFeature2Click(feature)}
              >
                {feature.heading}
              </button>
            ))}
          </div>
        </div>
        {/* screens */}
        <div
          className="mx-auto d-none d-lg-block my-5"
          style={{ width: "60%" }}
        >
          <div className="d-flex">
            <div className="feature-text w-100">
              <h1>{activeFeatureObj2.heading}</h1>{" "}
              <p>{activeFeatureObj2.description}</p>
            </div>
            <div className="w-100">
              <img
                src={activeFeatureObj1.image}
                alt="amigo-feature"
                className="img-fluid"
              ></img>
            </div>
          </div>
        </div>
        <div className="row my-5 d-lg-none">
          <div className="col-12 col-lg-6 feature-text">
            <h1>{activeFeatureObj2.heading}</h1>{" "}
            <p>{activeFeatureObj2.description}</p>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-flex d-lg-block justify-content-center">
              <img
                src={activeFeatureObj2.image}
                alt="amigo-feature"
                className="img-fluid w-75 mx-auto"
              ></img>
            </div>
          </div>
        </div>

        <div className="text-center mb-5 d-none d-lg-block fw-bold">
          <h4 className="m-0">
            As much weightage as Amigo holds for its customers,
          </h4>
          <h4 className="m-0">
            there are even greater things in store for businesses.
          </h4>
        </div>
      </div>
      <div className="get-started">
        <div className="container text-center text-white px-3 px-lg-5 py-4 py-lg-5">
          <div className="">
            <h1 className="mb-5">How to Get Started?</h1>
            <h3 className="mb-2">Download the Amigo App</h3>
            <p>Available on both iOS and Android platforms.</p>
            <div className="d-flex justify-content-center mb-5">
              <a href="/" tabIndex="0">
                <img
                  className="bn45"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/2560px-Google_Play_Store_badge_EN.svg.png"
                  alt="bn45"
                />
              </a>

              <a href="/" tabIndex="0">
                <img
                  className="bn46"
                  src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                  alt="bn45"
                />
              </a>
            </div>
            <div>
              <div className="mb-4 ">
                <Icon icon="clarity:mobile-solid" width="4em" height="4em" />
                <h3>Connect Your E-bike</h3>

                <p className="mx-auto text-width-features">
                  Pair your e-bike with the app via bluetooth for seamless
                  integration.
                </p>
              </div>
              <div className="mb-4">
                <Icon
                  icon="material-symbols-light:settings-outline"
                  width="4em"
                  height="4em"
                />
                <h3>Personalise Your Settings</h3>
                <p className="mx-auto text-width-features">
                  Customise riding modes, navigation and security settings
                  according to your preference.
                </p>
              </div>
              <div className="mb-4">
                <Icon icon="ion:bicycle-sharp" width="4em" height="4em" />
                <h3>Start Riding</h3>
                <p className="mx-auto text-width-features">
                  Enjoy a smooth, secure, and personalised ride with your buddy
                  navigation system via Amigo, which will guide you at every
                  step of the way.
                </p>
              </div>
              <br></br>
              <div className="my-3 my-lg-5">
                <p className="m-0 mb-4">
                  By leveraging these user-friendly upgrades in tech, Amigo’s
                  App transforms your daily rides into reliable and trusted
                  riding companions, ensuring you navigate effortlessly on every
                  journey.
                </p>
                <p>
                  All these attributes of Amigo enhance the e-bike riding
                  experience to empower riders with the knowledge and tools to
                  maintain the e-bikes in their prime.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-break"></div>
      <Footer />
    </>
  );
};

export default Tech;
