import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import WhatsAppBot from "../components/WhatsAppBot";
import "../css/ebikes.css";

const EBikes = () => {
  const eBikesHighlights = [
    {
      name: "Mountain Bikes",
      url: "/e-bikes/mountain",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/bikes/MTB.jpg",
      description:
        "Provides electric propulsion, situated in the wheel hub or frame, enabling efficient and assisted pedaling.",
    },
    {
      name: "Folding/Fat-Tyre Bikes",
      url: "/e-bikes/folding-fat-tyre",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/bikes/Folding+Bike.jpg",
      description:
        "Serving as the electronic conductor, the motor controller regulates power distribution",
    },
    {
      name: "City Bikes",
      url: "/e-bikes/city",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/bikes/City+Bike.jpg",
      description:
        "Essential for gear shifting, the derailleur in e-cycles facilitates seamless transitions",
    },
    {
      name: "Delivery Bikes",
      url: "/e-bikes/delivery",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/bikes/Delivery+Bike.jpg",
      description:
        "Stores energy, powering the electric motor and determining the e-cycle's range",
    },
  ];
  return (
    <>
      <Meta
        title={"List of all our E-bike Models for Wholesale | Dynem"}
        description={
          "Find the E-bike suited to your needs with Dynem's complete electric bike collection. Shop now for the best wholesale deals for electric bicycles."
        }
        cannonicalTag={"https://dynem.in/e-bikes"}
      />
      <Navbar />
      <WhatsAppBot bottom={"4%"} />
      {/* hero banner */}
      {/* <div className="">
        <picture className="main-banner-img">
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/mobile/main+banner/Artboard+2+(1).jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Artboard+1.jpg"
          />
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/homepage/desktop/main+banner/Artboard+1.jpg"
            alt="hero-banner-homepage"
            className="d-block w-100"
          />
        </picture>
      </div> */}

      {/* e-bikes cards */}
      <div className="container p-3 p-lg-0 my-3 my-lg-5">
        <h1 className="text-center mb-5">E-BIKES</h1>
        <div className="d-flex justify-content-center e-bikes-div">
          {eBikesHighlights.map((product, i) => (
            <div className="category-cards e-bikes-category-cards">
              <Link
                className="text-decoration-none"
                style={{ color: "inherit" }}
                to={`${product.url}`}
              >
                <img src={product.image} alt={product.name} className="w-100" />
                <div className="p-3">
                  <h1 className="h2-headings">{product.name}</h1>
                  <p>{product.description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="line-break"></div>
      <Footer />
    </>
  );
};

export default EBikes;
