import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../css/listing.css";
import Modal from "../components/Modal";
import Meta from "../components/Meta";
import WhatsAppBot from "../components/WhatsAppBot";
import ModalMobile from "../components/ModalMobile";

const ListingPage = () => {
  const { name } = useParams();
  const productHighlights = [
    {
      categoryName: "Mountain Bikes",
      title:
        "Mountain E-Bike Manufacturer, Buy Wholesale Mountain Electric Bikes",
      description:
        "Dynem manufacturers top mountain electric bikes that will take you anywhere. Check out our latest mountain E-bike and contact us to for wholesale purchase.",
      categoryDescription:
        "We're not just a mountain E-bike manufacturer; we're trailblazers in off-road adventure. Explore a range of e-bikes meticulously crafted for rugged terrains and thrilling rides. With powerful motors, robust frames, and packed with advanced electronics, it offers unmatched performance and durability, whatever the terrain. Whether it’s tackling steep climbs or navigating rocky paths, we bring unmatched biking experience throughout.",
      url: "mountain",
      coverImage: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/bannerImages/montain_desktop.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/bannerImages/montain_mobile.jpg",
      ],
      products: [
        {
          name: "Athena+",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/Athena+%2B.png",
          productSpecs: [
            "Motor: Bafang Middle Motor 48V 250W / 500W / 750W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 48V 17.5Ah 840Wh, Removable",
            "Display: Colorful LCD Display",
            'Tyre Size: F:Maxxis 29"*2.5 R:Maxxis 29"*2.4',
            "Brakes: Tektro Hydraulic Disc Brake",
            "Gear: Sram NX Eagle 11 Speed",
          ],
        },
        {
          name: "Carbon",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/CARBON.png",
          productSpecs: [
            "Motor: Bafang middle motor M600 48V 500W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 48V 17.5Ah 840Wh, Removable",
            "Display: Colorful LCD Display",
            'Tyre Size: F:Maxxis 29*2.5" R:Maxxis 29*2.4"',
            "Brakes: Tektro Hydraulic Disc Brake",
            "Gear: Sram NX Eagle 11 Speed",
          ],
        },
        {
          name: "Lucia",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/LUCIA.png",
          productSpecs: [
            "Motor: Bafang Middle Motor 48V 250W / 500W / 750W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 48V 15Ah 675Wh, Removable",
            "Display: Colorful LCD Display",
            'Tyre Size: Maxxis 29*2.4"',
            "Brakes: Tektro oil Disk brake",
            "Gear: SRAM 9 speed",
          ],
        },
        {
          name: "Neostar",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/NEOSTAR.png",
          productSpecs: [
            "Motor: 250W Motor",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V 10.2AH LI-Ion Battery",
            "Display: LCD Display",
            'Tyre Size: 29*2.4" Tyres',
            "Brakes: Dual Mechanical Disk Brakes with Auto Cut Off",
            "Gear: Shimano TY3000 7 Speed",
          ],
        },
        {
          name: "Ranger A",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/RANGER+A.png",
          productSpecs: [
            "Motor: 48V250W/500W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 48V12.8Ah 614WH, Removable",
            "Display: LCD display",
            'Tyre Size: CST 27.5*2.4"',
            "Brakes: Tektro Hydraulic F+R Disc Brake",
            "Gear: Shimano 21 speed",
          ],
        },
        {
          name: "Ranger B",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/RANGER+B.png",
          productSpecs: [
            "Motor: 36V250W/500W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V 12.8Ah/460.8Wh , Removable",
            "Display: LCD display",
            'Tyre Size: CST 27.5*2.1"',
            "Brakes: Hydraulic F+R Disc Brake",
            "Gear: Shimano 18 speed",
          ],
        },
        {
          name: "Ranger C",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/RANGER+C.png",
          productSpecs: [
            "Motor: Rear Motor 48V 250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 48V 12.8Ah/614Wh, Removable",
            "Display: LCD Display",
            'Tyre Size: CST29*2.1"',
            "Brakes: Tektro Front+Rear hydraulic brake",
            "Gear: Shimano 10 Speed",
          ],
        },
        {
          name: "Ranger D",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/RANGER+D.png",
          productSpecs: [
            "Motor: Rear Motor 48V 250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 48V 12.8Ah/614Wh, Removable",
            "Display: LCD Display",
            'Tyre Size: CST29*2.1"',
            "Brakes: Tektro Front+Rear hydraulic brake",
            "Gear: Shimano 10 Speed",
          ],
        },
        {
          name: "T-Rex Air",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/T-+Rex+Air.png",
          tagline: "This is a Revolution on Wheels!",
          productSpecs: [
            "Motor: 250W Motor",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V 10.2AH LI-Ion Battery",
            "Display: M5 LCD Display",
            'Tyre Size: 29*2.4" Tyres',
            "Brakes: Dual Mechanical Disk Brakes with Auto Cut Off",
            "Gear: Shimano TY3000 7 Speed",
          ],
        },
        {
          name: "Tango",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/TANGO.png",
          productSpecs: [
            "Motor: Bafang Middle Motor 48V250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: LG 48V 15Ah 720Wh, Removable",
            "Display: LCD Display",
            'Tyre Size: Kenda 27.5*2.2"',
            "Brakes: Tektro Hydraulic oil Disc Brake",
            "Gear: Sram 9 Speed",
          ],
        },
        {
          name: "Trip-E",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Mountain/TRIP-+E.png",
          productSpecs: [
            "Motor: Rear Motor 36V 250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V 12.8Ah /460Wh , Removable",
            "Display: LCD Display",
            'Tyre Size: CST 27.5*2.1"',
            "Brakes: Tektro Front+Rear hydraulic brake",
            "Gear: Shimano 24 speed",
          ],
        },
      ],
      faqs: [
        {
          question: "What DYNEM is a leading mountain e-bike manufacturer?",
          answer:
            "DYNEM stands out with its cutting-edge technology, robust construction, and commitment to sustainability. Our mountain e-bikes are designed to tackle the toughest terrains, providing unmatched performance and reliability.",
        },
        {
          question: "Can we customise the mountain e-bikes to fit our brand?",
          answer:
            "Absolutely! DYNEM offers extensive OEM services, allowing you to customise e-bikes with your branding, specifications, and unique features. Our team works closely with you to ensure your vision is perfectly realised.",
        },
        {
          question: "What kind of support does DYNEM offer post-purchase?",
          answer:
            "We provide comprehensive after-sales support, including technical assistance, maintenance tips, and a dedicated customer service team to address any issues promptly, ensuring your business runs smoothly.",
        },
        {
          question:
            "How do DYNEM's mountain e-bikes enhance the riding experience?",
          answer:
            "Our mountain e-bikes integrate advanced features like GPS navigation, real-time performance monitoring, and powerful motors. These enhancements ensure a superior riding experience, whether for recreational or professional use.",
        },
        {
          question:
            "What is the production capacity of DYNEM's mountain e-bikes?",
          answer:
            "DYNEM's state-of-the-art facility can produce and customise up to 5,00,000 e-bikes annually, ensuring we can meet high-volume demands and deliver on time, every time.",
        },
        {
          question:
            "How does DYNEM ensure the quality of its mountain e-bikes?",
          answer:
            "Quality is our top priority. Our production processes are streamlined and equipped with advanced technology to ensure every e-bike meets the highest standards. Rigorous testing and quality control measures are in place to guarantee reliability and durability.",
        },
      ],
    },
    {
      categoryName: "Folding / Fat-Tyre Bikes",
      url: "folding-fat-tyre",
      title:
        "Folding Fat Tyre E-Bike Manufacturer, Buy Wholesale Folding Fat Tyre Electric Bikes",
      description:
        "Dynem manufacturers top folding fat tyre electric bikes blending convenience and innovation. Check out our latest foldable E-bike and contact us to for wholesale purchase.",
      categoryDescription:
        "As a top-tier folding eBike manufacturer, DYNEM brings you the ultimate blend of convenience and style. Our folding e-bikes are perfect for city explorers and jet-setters, offering easy storage and portability without skimping on power. With sleek designs, zippy motors, and solid construction, it’s crafted to make your ride smooth and fun. Dive into the freedom and flexibility of folding e-bikes, designed to keep up with fast-paced, ever-changing life.",
      coverImage: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/bannerImages/folding_desktop.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/bannerImages/folding_mobile.jpg",
      ],
      products: [
        {
          name: "Dolphin",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/DOLPHIN.png",
          tagline: "The Dolphin is Fun and Free Flowing! Best in Class E-bike.",
          productSpecs: [
            "Motor: Ananda 36V 250W Rear-Motor",
            "Battery: 7.5AH Integrated Battery",
            "Display: LCD Display",
            'Tyre Size: 20*1.9" Tyres',
            "Brakes: Tektro Mechanical Disc Brakes",
            "Gear: 7 Speed Derailleur",
          ],
        },
        {
          name: "Echo",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/ECHO.png",
          tagline: "Elevates your Performace Across All Terrains",
          productSpecs: [
            "Motor: XiongFeng 36V 250W Rear-Motor",
            "Battery: 8.7AH Battery",
            "Display: KM-3209 Display",
            'Tyre Size: 20*1.95" Tyres',
            "Brakes: Mechanical Disc Brakes",
            "Gear: 6 Speed Derailleur",
          ],
        },
        {
          name: "Strada V1",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/STRADA+V1.jpg",
          tagline: "Versatile, Poweful Motors to Ride Beyond your Limits",
          productSpecs: [
            "Motor: Bafang 36V 250W Rear-Motor",
            "Battery: 9.6AH Battery",
            "Display: LCD Display",
            'Tyre Size: 20*1.95" Tyres',
            "Brakes: Tektro Mechanical Disc Brakes",
            "Gear: 7 Speed Derailleur",
          ],
        },
        {
          name: "Strada V2",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/STRADA+V2.png",
          tagline: "Versatile, Poweful Motors to Ride Beyond your Limits",
          productSpecs: [
            "Motor: Bafang 36V 250W Rear-Motor",
            "Battery: 10.5AH Battery",
            "Display: Integrated LCD Display",
            'Tyre Size: 20*1.95" Tyres',
            "Brakes: Tektro Hydraullic Disc Brakes",
            "Gear: 7 Speed Derailleur",
          ],
        },
        {
          name: "Aurora",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/AURORA.png",
          productSpecs: [
            "Motor: Rear Motor 36V 250W",
            "Battery: 36V 7.8Ah/281Wh, Removable",
            "Display: LED display",
            'Tyre Size: 20*1.75"',
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Single speed",
          ],
        },
        {
          name: "Doodle",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/DOODLE.png",
          productSpecs: [
            "Motor: Rear Motor 36V 350W",
            "Battery: 36V 12.75Ah 459Wh , Removable",
            "Display: LCD Display",
            'Tyre Size: CST 20*4.0"',
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Shimano 7 Speed",
          ],
        },
        {
          name: "Fusion Plus",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/FUSION+PLUS.png",
          productSpecs: [
            "Motor: Rear Motor 48V 350W",
            "Battery: 48V/10.2Ah 490Wh,Removable",
            "Display: LCD Display",
            'Tyre Size: CST tire 20*3.0"',
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Shimano 7 Speed",
          ],
        },
        {
          name: "Fusion X",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/FUSION+X.png",
          productSpecs: [
            "Motor: Rear Motor 48V 350W",
            "Battery: 48V/10.2Ah 490Wh,Removable",
            "Display: LCD Display",
            'Tyre Size: CST tire 20*3.0"',
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Shimano 7 Speed",
          ],
        },
        {
          name: "Lite",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/Lite.png",
          productSpecs: [
            "Motor: Rear Motor 24V 250W",
            "Battery: 25.2V10Ah 252Wh, Removable",
            "Display: LCD Display",
            'Tyre Size: ChaoYang 20*1.75"',
            "Brakes: Mechanic F+R Disk Brake",
            "Gear: Single speed",
          ],
        },
        {
          name: "Plymouth",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/PLYMOUTH.png",
          productSpecs: [
            "Motor: Rear motor 36V 250W",
            "Battery: 36V11.6Ah 418Wh, Removable",
            "Display: LCD Display",
            "Tyre Size: CST 700*40C",
            "Brakes: Tektro Hydraullic Disc Brakes",
            "Gear: Shimano 7 speed",
          ],
        },
        {
          name: "Premium",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/PREMIUM.png",
          productSpecs: [
            "Motor: Middle Motor 48V250W/500W",
            "Battery: 2 pcs*48V 10.4Ah/998.4Wh",
            "Display: LCD Display",
            'Tyre Size: CST tire 20*4.0"',
            "Brakes: Hydraulic F+R Disc Brake",
            "Gear: Shimano 7 Speed",
          ],
        },
        {
          name: "Swift",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/SWIFT.png",
          productSpecs: [
            "Motor: Rear motor 48V 300W",
            "Battery: 48V7.8Ah,(Max 40cells), Removable",
            "Display: LCD Display",
            'Tyre Size: CST 20*2.6"',
            "Brakes: Front+Rear disc Brake",
            "Gear: Shimano 7 Speed",
          ],
        },
        {
          name: "Toledo",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/TOLEDO.png",
          productSpecs: [
            "Motor: Rear Motor 36V 250W",
            "Battery: 36V 13Ah/468Wh, Removable",
            "Display: LCD Display",
            'Tyre Size: 20*1.95" Tyres',
            "Brakes: Mechanic F+R Disk Brake",
            "Gear: Shimano 7 Speed",
          ],
        },
        {
          name: "Xplorer",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Foldable+and+Fat+Tyre/XPLORER.png",
          productSpecs: [
            "Motor: Rear Motor 36V 350W",
            "Battery: 36V*13AH",
            "Display: LCD M6H LCD Display",
            'Tyre Size: Kenda tire 20*4.0"',
            "Brakes: Front+Rear mechanical disc Brake",
            "Gear: Shimano 7 Speed",
          ],
        },
      ],
      faqs: [
        {
          question:
            "What distinguishes DYNEM as a top folding e-bike manufacturer?",
          answer:
            "DYNEM is renowned for innovative designs, superior engineering, and a commitment to sustainability. Our folding e-bikes are compact, durable, and perfect for urban commuting, offering convenience without compromising on performance.",
        },
        {
          question:
            "Are your folding and fat-tyre e-bikes customizable for our brand?",
          answer:
            "Yes, DYNEM offers comprehensive OEM services. You can customise our e-bikes with your brand’s logo, specific features, and design preferences, ensuring they align perfectly with your market needs.",
        },
        {
          question:
            "What after-sales support does DYNEM provide for folding and fat-tyre e-bikes?",
          answer:
            "We offer extensive post-purchase support, including technical assistance, maintenance guidance, and a dedicated customer service team to ensure your business operations remain seamless and efficient.",
        },
        {
          question:
            "What are the key features of DYNEM's folding and fat-tyre e-bikes?",
          answer:
            "Our e-bikes are equipped with features like quick-fold mechanisms, robust fat tyres for all-terrain capability, powerful motors, and integrated smart technology, making them ideal for both urban and off-road adventures.",
        },
        {
          question:
            "What is the production capacity for DYNEM's folding and fat-tyre e-bikes?",
          answer:
            "Our advanced manufacturing facility can produce and customise up to 500,000 e-bikes annually, allowing us to meet large-scale orders and deliver on tight deadlines.",
        },
        {
          question:
            "How does DYNEM ensure the quality of its folding and fat-tyre e-bikes?",
          answer:
            "We maintain stringent quality control processes throughout our production line. Each e-bike undergoes rigorous testing and inspection to ensure it meets the highest standards of durability, safety, and performance.",
        },
      ],
    },
    {
      categoryName: "City Bikes",
      url: "city",
      title:
        "City E-Bike Manufacturer, Buy Wholesale City Electric Bikes in Bulk",
      description:
        "Dynem manufacturers top city electric bikes for convenient city commute. Check out our latest city E-bike and contact us to for wholesale purchase.",
      categoryDescription:
        "As a premier city bike manufacturer, DYNEM redefines urban commuting with sleek, efficient, and stylish rides. Our city bikes blend cutting-edge technology with modern design, ensuring you glide through traffic with ease. Perfect for the hustle and bustle of city life, it offers unparalleled comfort and reliability, making daily commute a breeze.",
      coverImage: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/bannerImages/city_desktop.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/bannerImages/city_mobile.jpg",
      ],
      products: [
        {
          name: "Cosmos",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/Cosmos.png",
          tagline: "Boost your Ride, Ignite your Syle!",
          productSpecs: [
            "Motor: Jiabo 36V 250W",
            "Battery: 10.4AH Battery",
            "Display: LCD Display",
            "Tyre Size: 700*38C Tyres",
            "Brakes: Mechanical Disc Brakes",
            "Gear: 7 Speed Derailleur",
          ],
        },
        {
          name: "Brita C047",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/BRITA+C063M.png",
          productSpecs: [
            "Motor: Rear Motor 36V 250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V10.2AH 367Wh, Removable",
            "Display: LED Display",
            "Tyre Size: CST 700C*38C",
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Shimano 7 speed",
          ],
        },
        {
          name: "Brita C056",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/BRITA.png",
          productSpecs: [
            "Motor: Rear 36V 250W Motor",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V 10Ah/360Wh",
            "Display: Integrated handbar LCD Display",
            "Brakes: Zoom Hydraulic Oil Disc Brake",
            "Gear: Shimano 8 Speed",
            "Tyre Size: CST 700C*38C",
          ],
        },
        // {
        //   name: "C072",
        //   imgURl:
        //     "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/C072.png",
        //   productSpecs: [
        //     "Motor: Rear 36V 250W Motor",
        //     "Frame: 6061 Aluminium Alloy",
        //     "Battery: 36V 10Ah/360Wh",
        //     "Display: Integrated handbar LCD Display",
        //     "Brakes: Zoom Hydraulic Oil Disc Brake",
        //     "Gear: Shimano 8 Speed",
        //     "Tyre Size: CST 700C*38C",
        //   ],
        // },
        {
          name: "Diana",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/DAINA.png",
          productSpecs: [
            "Motor: 24V 250W",
            "Frame: Steel Frame",
            "Battery: 25.2V 7.8Ah 196Wh, Removable",
            "Display: LED display",
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Shimano 6 speed",
            'Tyre Size: Kenda 20*1.75"',
          ],
        },
        {
          name: "Dove",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/DOVE.png",
          productSpecs: [
            "Motor: Rear Motor 36V 250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V9.6Ah 346Wh, Removable, With USB Port",
            "Display: LCD Display",
            "Brakes: Tektro Hydraulic Oil Disc Brake",
            "Gear: Shimano 7 speed",
            "Tyre Size: Kenda 700*40C",
          ],
        },
        {
          name: "Emerald",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/EMERALD.png",
          productSpecs: [
            "Motor: Bafang rear motor 36V 250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V9.6AH 346Wh, Removable",
            "Display: LCD Display",
            "Brakes: Front+Rear Tektro Hydraulic disc Brake",
            "Gear: Shimano 7 speed",
            "Tyre Size: CST tire 700*45c with reflective",
          ],
        },
        {
          name: "Greyd",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/GREYD.png",
          productSpecs: [
            "Motor: Rear 36V 250W Motor",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V 12.8AH",
            "Display: Integrated Stem Display",
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Shimano 7 Speed",
            "Tyre Size: CST 700C*38C",
          ],
        },
        {
          name: "Hansel",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/HANSEL.png",
          productSpecs: [
            "Motor: Bafang Middle Motor 48V250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: LG 48V 15Ah 720Wh, Removable",
            "Display: LCD display",
            "Brakes: Tektro Hydraulic oil Disc Brake",
            "Gear: Sram 9 Speed",
            'Tyre Size: Kenda 27.5*2.2"',
          ],
        },
        {
          name: "Polaris",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/POLARIS.png",
          productSpecs: [
            "Motor: Bafang middel motor 48V 250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 48V15AH/ 720Wh, Removable",
            "Display: LCD Display",
            "Brakes: Front+rear Tektro Hydraulic disc brake",
            "Gear: Shimano 9 Speed",
            'Tyre Size: CST 27.5"*2.20"',
          ],
        },
        {
          name: "Shanghai",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/SHANGHAI.png",
          productSpecs: [
            "Motor: Bafang rear motor 36V 250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V9.6AH 346Wh, Removable",
            "Display: LCD Display",
            "Brakes: Front+Rear Tektro Hydraulic disc Brake",
            "Gear: Shimano 7 speed",
            "Tyre Size: CST tire 700*45c with reflective",
          ],
        },
        {
          name: "Simba",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/SIMBA.png",
          productSpecs: [
            "Motor: Rear 36V 250W Motor",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V9.6Ah 346Wh, Removable, With USB Port",
            "Display: LCD Display",
            "Brakes: Tektro Hydraulic Oil Disc Brake",
            "Gear: Shimano 7 Speed",
            "Tyre Size: Kenda 700*40C",
          ],
        },
        {
          name: "Velocity",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/VELOCITY.png",
          productSpecs: [
            "Motor: Rear 36V 250W Motor",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V13AH 468Wh , Removable",
            "Display: LED Display",
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Shimano 7 Speed",
            "Tyre Size: CST 700C*38C",
          ],
        },
        {
          name: "Venlo",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/VENLO.png",
          productSpecs: [
            "Motor: Rear Motor 36V250W",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V 10.2Ah 367Wh,Removable",
            "Display: LCD Display",
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Shimano 7 Speed",
            "Tyre Size: CST 700C*38C",
          ],
        },
        {
          name: "Viper",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/VIPER.png",
          productSpecs: [
            "Motor: Rear 36V 250W Motor",
            "Frame: 6061 Aluminium Alloy",
            "Battery: 36V 10.2Ah 367Wh, Removable",
            "Display: LCD Display",
            "Brakes: F+R Mechanic Disk Brake",
            "Gear: Shimano 7 Speed",
            "Tyre Size: CST 700C*38C",
          ],
        },
        {
          name: "Blaze",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/X1.png",
          productSpecs: [
            "Motor: 36V 250W Rear Hub Motor",
            "Frame: Steel Frame",
            "Front Fork: Front Fork Suspension with 100 mm Travel",
            "Battery: 36V 7.65 Ah Li-Ion Removable Battery",
            "Display: Battery Indicator on Throttle",
            "Brakes: Mechanical Disc Brakes with Auto Cut-off",
            "Gear: Single Speed",
            'Tyre Size: 27.5*2.1"',
          ],
        },
        {
          name: "Dash",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/City/X2.png",
          productSpecs: [
            "Motor: Rear 36V 250W Motor",
            "Frame: Step-Through Steel Frame",
            "Front Fork: Front Fork Suspension with 100 mm Travel",
            "Battery: 36V 7.65 Ah Li-Ion Removable Battery",
            "Display: P9 LCD Display",
            "Brakes: Mechanical Disc Brakes with Auto Cut-off",
            "Gear: Single 7 Speed",
            'Tyre Size: 27.5*2.1"',
          ],
        },
      ],
      faqs: [
        {
          question: "How can DYNEM's city bikes benefit urban commuters?",
          answer:
            "DYNEM's city bikes are designed to offer urban commuters a convenient, eco-friendly, and stylish mode of transportation. Our e-bikes feature ergonomic designs, smart connectivity, and integrated lighting systems, ensuring a comfortable and efficient ride through city streets.",
        },
        {
          question:
            "Can we customise DYNEM's city bikes to fit our brand's identity?",
          answer:
            "Absolutely! Our OEM services allow you to customise city bikes with your brand’s logo, colour scheme, and specific features, ensuring the bikes resonate with your target market.",
        },
        {
          question:
            "What kind of after-sales support does DYNEM offer for city bikes?",
          answer:
            "DYNEM provides comprehensive after-sales support, including technical assistance, regular maintenance tips, and a dedicated customer service team to address any issues promptly and efficiently.",
        },
        {
          question: "What features are standard on DYNEM's city bikes?",
          answer:
            "Our city bikes come with features such as lightweight frames, ergonomic designs, integrated lighting systems, and smart connectivity options, ensuring a smooth and enjoyable riding experience in urban environments.",
        },
        {
          question: "What is DYNEM's production capacity for city bikes?",
          answer:
            "Our world-class manufacturing facility is capable of producing and customising up to 500,000 city bikes annually, enabling us to fulfil large orders and meet tight delivery schedules.",
        },
        {
          question: "How does DYNEM ensure the quality of its city bikes?",
          answer:
            "We implement rigorous quality control measures at every stage of production. Each city bike undergoes extensive testing and inspection to ensure it meets the highest standards of safety, durability, and performance.",
        },
      ],
    },
    {
      categoryName: "Delivery Bikes",
      url: "delivery",
      title:
        "Delivery E-Bike Manufacturer, Best Electric Bike for Delivery Wholesale",
      description:
        "Dynem manufacturers top delivery electric bikes for efficient and quick deliveries. Check out our latest E-bike for delivery and contact us to for wholesale purchase.",
      categoryDescription:
        "As a leading delivery bike manufacturer, DYNEM delivers top-tier performance and reliability for every delivery. Our e-bikes are designed for the demands of urban logistics, featuring robust construction, powerful motors, and ample cargo space. Whether it's food, packages, or grocery runs, our delivery bikes ensure swift and eco-friendly transport, keeping business moving smoothly.",
      coverImage: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/bannerImages/delivery_desktop.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/bannerImages/delivery_mobile.jpg",
      ],
      products: [
        {
          name: "Bumble",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Delivery+and+Cargo/BUMBLE.png",
          tagline: "E-Bikes Crafted for Utility and Speed",
          productSpecs: [
            "Motor: Jiabo 36V 350W Rear-Motor",
            "Battery: 7.8AH Integrated Battery",
            "Display: LCD-P3H Display",
            'Tyre Size: 20*2.35" Tyres',
            "Brakes: Tektro Hydraulic Oil Disc Brakes",
            "Gear: 7 Speed Derailleur",
          ],
        },
        {
          name: "Nebula",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Delivery+and+Cargo/Nebula.png",
          tagline: "Exceptional Performance and Speed on any Terrain",
          productSpecs: [
            "Motor: Jiabo 48V 500W Rear-Motor",
            "Battery: 10.4AH Battery",
            "Display: LCD Display",
            'Tyre Size: 20*2.0" Tyres',
            "Brakes: Mechanical Disc Brakes",
            "Gear: 7 Speed Derailleur",
          ],
        },
        {
          name: "Dellex",
          imgURl:
            "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/products/Delivery+and+Cargo/DELLEX.png",
          productSpecs: [
            "Motor: Bafang rear motor 48V 350W",
            "Battery: 48V16AH 768Wh,Removable",
            "Display: LCD Display",
            'Tyre Size: Kenda tire 20*4.0"',
            "Brakes: Tektro Hydraulic Disc Brake",
            "Gear: Shimano 8 Speed",
          ],
        },
      ],
      faqs: [
        {
          question:
            "What makes DYNEM's delivery bikes suitable for urban logistics?",
          answer:
            "DYNEM's delivery bikes are engineered for efficiency and reliability in urban logistics. With robust frames, ample cargo capacity, and customizable features, they streamline delivery operations while reducing carbon footprint.",
        },
        {
          question:
            "Can DYNEM customise delivery bikes to meet specific business needs?",
          answer:
            "Yes, our OEM services allow businesses to tailor delivery bikes with branding, cargo configurations, and additional features like GPS tracking and advanced electronics, ensuring optimal performance in varied environments.",
        },
        {
          question:
            "How does DYNEM support businesses integrating delivery bikes into their fleets?",
          answer:
            "We offer comprehensive support, including fleet customization, maintenance training, and responsive customer service. Our goal is to enhance operational efficiency and minimise downtime for your delivery operations.",
        },
        {
          question: "What are the key features of DYNEM's delivery bikes?",
          answer:
            "Our delivery bikes feature durable construction, ergonomic designs, secure cargo compartments, and advanced safety features. They are designed to withstand rigorous use while maintaining safety and efficiency.",
        },
        {
          question:
            "What is the production capacity for DYNEM's delivery bikes?",
          answer:
            "DYNEM's manufacturing facility can produce up to 500,000 delivery bikes annually, ensuring scalability and timely delivery to meet the demands of growing logistics operations.",
        },
        {
          question:
            "How does DYNEM ensure the durability and longevity of its delivery bikes?",
          answer:
            "We conduct rigorous testing and quality checks throughout the manufacturing process. Our delivery bikes are built to withstand daily use and varying weather conditions, ensuring reliability and longevity for businesses.",
        },
      ],
    },
  ];
  const targetCategory = productHighlights.find(
    (category) => category.url === name
  );
  return (
    <>
      <Meta
        title={targetCategory.title}
        description={targetCategory.description}
        cannonicalTag={`https://dynem.in/e-bikes/${name}`}
      />
      <Navbar />
      <WhatsAppBot bottom={"4%"} />
      {/* hero banner */}
      <div className="position-relative">
        <picture className="main-banner-img">
          <source
            media="(max-width:650px)"
            srcSet={targetCategory.coverImage[1]}
          />
          <source
            media="(min-width:651px)"
            srcSet={targetCategory.coverImage[0]}
          />
          <img
            src={targetCategory.coverImage[0]}
            alt="hero-banner-homepage"
            className="d-block w-100"
          />
        </picture>
        <div className="overlay">
          <div className="position-absolute category-banner-heading">
            <h1 className="text-center banner-heading">
              {targetCategory.categoryName}
            </h1>
          </div>
        </div>
      </div>
      {/* product cards */}
      <div className="container p-3 p-lg-0 my-4 mt-lg-5">
        <p className="mb-5">{targetCategory.categoryDescription}</p>
        <div className="grid-container">
          {targetCategory.products.map((product, i) => (
            <div className="mb-4 category-cards listing-category-cards">
              <div className="">
                <img
                  src={product.imgURl}
                  alt={product.name}
                  className="w-100"
                  // style={{
                  //   borderRight: "0.5px solid black",
                  //   borderLeft: "0.5px solid black",
                  //   borderTop: "0.5px solid black",
                  // }}
                />
                <div className="p-3 listing-content">
                  <h2 className="h2-headings dynem-color mb-3">
                    {product.name}
                  </h2>
                  <p className="fw-bold">{product.tagline}</p>
                  <div className="product-specs mt-4">
                    {product.productSpecs.map((highlight, i) => (
                      <p className="m-0 mb-2" key={i}>
                        {highlight}
                      </p>
                    ))}
                  </div>
                </div>
                <div
                  className="p-3 pb-4"
                  // style={{
                  //   borderRight: "0.5px solid black",
                  //   borderLeft: "0.5px solid black",
                  //   borderBottom: "0.5px solid black",
                  // }}
                >
                  <Modal className="d-none d-lg-block w-100 primary-button" />
                  {/* <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal1"
                  >
                    Launch demo modal
                  </button>

                  <div
                    class="modal fade"
                    id="exampleModal1"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">
                            Modal title
                          </h1>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">...</div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button type="button" class="btn btn-primary">
                            Save changes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <ModalMobile className="d-lg-none w-100 primary-button" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container mb-5">
        <h2 className="mb-4">FAQs</h2>
        <div class="accordion" id="accordionExample">
          {targetCategory.faqs.map((faq, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`heading${index}`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="false"
                  aria-controls={`collapse${index}`}
                >
                  {faq.question}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${index}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">{faq.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="line-break"></div>
      <Footer />
    </>
  );
};

export default ListingPage;
