import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "../css/landingPage.css";
import cst from "../images/partnerLogos/cst.png";
import kenli from "../images/partnerLogos/kenli.png";
import kmc from "../images/partnerLogos/kmc.png";
import maxxis from "../images/partnerLogos/maxxis.png";
import wtb from "../images/partnerLogos/wtb.png";
import schwalbe from "../images/partnerLogos/schwalbe.png";
import neco from "../images/partnerLogos/neco.png";
import ralson from "../images/partnerLogos/ralson.png";
import prowheel from "../images/partnerLogos/prowheel.png";
import sunrace from "../images/partnerLogos/sunrace.png";
import shimano from "../images/partnerLogos/shimano.png";
import sram from "../images/partnerLogos/sram.png";
import rockshox from "../images/partnerLogos/rockshox.png";
import microshift from "../images/partnerLogos/microshift.png";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import Meta from "../components/Meta";
import Modal from "../components/Modal";
import ModalMobile from "../components/ModalMobile";
import ReactPlayer from "react-player";
import CustomBike from "../components/CustomBike";
import "../css/navbar.css";
import logo from "../images/brandLogos/Dynem red white.png";
import { Icon } from "@iconify/react";

const GetQuoteLanding = () => {
  const [open, openNav] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const closeNavforDeadLink = () => {
    openNav(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designation, setDesignation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [enquiry, setEnquiry] = useState("");

  const componentData = [
    {
      key: "Frame",
    },
    {
      key: "Motor",
    },
    {
      key: "Derailleur",
    },
    {
      key: "Battery",
    },
    {
      key: "Display",
    },
  ];
  const [activeComponent, setActiveComponent] = useState("Frame");
  const handleComponentClick = (feature) => {
    setActiveComponent(feature.key);
  };

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const reqBody = {
      name,
      email,
      phoneNumber,
      companyName,
      designation,
      country,
      enquiry,
    };
    const { data } = await axios.post(
      "/api/form/request-a-quote",
      reqBody,
      config
    );
    if (data) {
      navigate("/details-submitted");
    }
  };
  return (
    <>
      {/* landing page main banner */}
      <Meta cannonicalTag={"https://dynem.in/get-quote"} />
      {/* desktop navbar */}
      <div
        className="position-sticky w-100 navbar"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className="row container mx-auto p-0">
          <div className="col-12 col-md-4 d-flex justify-content-start p-0">
            <img src={logo} alt="dynem-logo" className="logo"></img>
          </div>
          <div className="col-5 d-flex nav-items p-0"></div>
          <div className="col-3 p-0 d-none d-lg-block d-md-block">
            <div className="d-flex justify-content-end align-items-center">
              <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
            </div>
          </div>
        </div>
      </div>
      <div className="get-quote-landing">
        {/* hero-banner */}
        <div id="home-page-hero-slider" className="home-main-slider mb-5">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
            data-interval="1000"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                style={{
                  width: "11px",
                  height: "11px",
                }}
                className="active rounded-circle"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                style={{
                  width: "11px",
                  height: "11px",
                }}
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                className="rounded-circle"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                style={{
                  width: "11px",
                  height: "11px",
                }}
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                className="rounded-circle"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                style={{
                  width: "11px",
                  height: "11px",
                }}
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="3"
                className="rounded-circle"
                aria-label="Slide 4"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner1_mb.jpg"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner1.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner1.jpg"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="d-none d-lg-block get-quote-banner-button position-absolute">
                  <div className="d-flex" style={{ gap: "1.5em" }}>
                    <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
                    <a
                      href="https://calendly.com/contactus-dynem/introduction-to-dynem?month=2024-08"
                      className="text-decoration-none primary-button fw-bold px-4 py-2 rounded-3"
                    >
                      Schedule a Call
                    </a>
                  </div>
                </div>
                <div
                  className="d-flex d-lg-none get-quote-banner-button-mb position-absolute justify-content-center w-100"
                  style={{ gap: "1em" }}
                >
                  <ModalMobile className="d-lg-none primary-button fw-bold px-4 py-2 rounded-3" />
                  <a
                    href="https://calendly.com/contactus-dynem/introduction-to-dynem?month=2024-08"
                    className="text-decoration-none primary-button fw-bold px-4 py-2 rounded-3"
                    style={{ fontSize: "0.8em" }}
                  >
                    Schedule a Call
                  </a>
                </div>
              </div>
              <div className="carousel-item position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner2_mb.png"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner2.png"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner2_mb.png"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="d-none d-lg-block get-quote-banner-button position-absolute">
                  <div className="d-flex" style={{ gap: "1.5em" }}>
                    <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
                    <a
                      href="https://calendly.com/contactus-dynem/introduction-to-dynem?month=2024-08"
                      className="text-decoration-none primary-button fw-bold px-4 py-2 rounded-3"
                    >
                      Schedule a Call
                    </a>
                  </div>
                </div>
                <div
                  className="d-flex d-lg-none get-quote-banner-button-mb position-absolute justify-content-center w-100"
                  style={{ gap: "1em" }}
                >
                  <ModalMobile className="d-lg-none primary-button fw-bold px-4 py-2 rounded-3" />
                  <a
                    href="https://calendly.com/contactus-dynem/introduction-to-dynem?month=2024-08"
                    className="text-decoration-none primary-button fw-bold px-4 py-2 rounded-3"
                    style={{ fontSize: "0.8em" }}
                  >
                    Schedule a Call
                  </a>
                </div>
              </div>
              <div className="carousel-item position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner3_mb.png"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner3.png"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner3.png"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="d-none d-lg-block get-quote-banner-button position-absolute">
                  <div className="d-flex" style={{ gap: "1.5em" }}>
                    <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
                    <a
                      href="https://calendly.com/contactus-dynem/introduction-to-dynem?month=2024-08"
                      className="text-decoration-none primary-button fw-bold px-4 py-2 rounded-3"
                    >
                      Schedule a Call
                    </a>
                  </div>
                </div>
                <div
                  className="d-flex d-lg-none get-quote-banner-button-mb position-absolute justify-content-center w-100"
                  style={{ gap: "1em" }}
                >
                  <ModalMobile className="d-lg-none primary-button fw-bold px-4 py-2 rounded-3" />
                  <a
                    href="https://calendly.com/contactus-dynem/introduction-to-dynem?month=2024-08"
                    className="text-decoration-none primary-button fw-bold px-4 py-2 rounded-3"
                    style={{ fontSize: "0.8em" }}
                  >
                    Schedule a Call
                  </a>
                </div>
              </div>
              <div className="carousel-item position-relative">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner4_mb.png"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner4.png"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/banner4.png"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="d-none d-lg-block get-quote-banner-button position-absolute">
                  <div className="d-flex" style={{ gap: "1.5em" }}>
                    <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
                    <a
                      href="https://calendly.com/contactus-dynem/introduction-to-dynem?month=2024-08"
                      className="text-decoration-none primary-button fw-bold px-4 py-2 rounded-3"
                    >
                      Schedule a Call
                    </a>
                  </div>
                </div>
                <div
                  className="d-flex d-lg-none get-quote-banner-button-mb position-absolute justify-content-center w-100"
                  style={{ gap: "1em" }}
                >
                  <ModalMobile className="d-lg-none primary-button fw-bold px-4 py-2 rounded-3" />
                  <a
                    href="https://calendly.com/contactus-dynem/introduction-to-dynem?month=2024-08"
                    className="text-decoration-none primary-button fw-bold px-4 py-2 rounded-3"
                    style={{ fontSize: "0.8em" }}
                  >
                    Schedule a Call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* sub banner */}
        <div className="container d-none d-lg-block">
          <Splide
            className="mb-2 mb-lg-0 p-0 align-items-center text-center"
            options={{
              arrows: true,
              start: 0,
              drag: false,
              type: "loop",
              pagination: true,
              autoplay: true,
              gap: "3em",
              padding: "em",
              pauseOnHover: true,
              resetProgress: false,
              perPage: 1,
              speed: 1000,
              interval: 4000,
              breakpoints: {
                700: {
                  drag: true,
                  autoplay: false,
                  pagination: "false",
                  perPage: 1,
                  perMove: 1,
                  gap: "2em",
                  padding: "0em",
                  arrows: false,
                  pauseOnHover: false,
                  resetProgress: false,
                },
              },
            }}
            muted={true}
          >
            <SplideSlide className="h-100">
              <div className="">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_1_mob.png"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_1.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_1.jpg"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div className="">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_2_mob.png"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_2.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_3.jpg"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="d-lg-none">
          <Splide
            className="mb-2 mb-lg-0 p-0 align-items-center text-center"
            options={{
              arrows: true,
              start: 0,
              drag: false,
              type: "loop",
              pagination: true,
              autoplay: true,
              gap: "3em",
              padding: "em",
              pauseOnHover: true,
              resetProgress: false,
              perPage: 1,
              speed: 1000,
              interval: 4000,
              breakpoints: {
                700: {
                  drag: true,
                  autoplay: true,
                  pagination: "false",
                  perPage: 1,
                  perMove: 1,
                  gap: "2em",
                  padding: "0em",
                  arrows: false,
                  pauseOnHover: false,
                  resetProgress: false,
                  speed: 1000,
                  interval: 3000,
                },
              },
            }}
            muted={true}
          >
            <SplideSlide className="h-100">
              <div className="">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_1_mob.png"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_1.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_1.jpg"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div className="">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_2_mob.png"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_2.jpg"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/subbanner_3.jpg"
                    alt="hero-banner"
                    className="d-block w-100"
                  />
                </picture>
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
        </div>
        {/* world class products */}
        <div className="news">
          <div className="container mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5">
              We Manufacture World Class Products
            </h2>
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div
                  className="p-3 rounded-4"
                  style={{ backgroundColor: "white" }}
                >
                  <Link
                    to={"/components"}
                    className="text-decoration-none text-dark"
                  >
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/bikeAd.jpg"
                        alt=""
                        className="img-fluid rounded-4"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">E-bikes</h5>
                  </Link>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div
                  className="p-3 rounded-4"
                  style={{ backgroundColor: "white" }}
                >
                  <Link
                    to={"/components"}
                    className="text-decoration-none text-dark"
                  >
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/motorAd.jpg"
                        alt=""
                        className="img-fluid rounded-4"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Motors</h5>
                  </Link>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div
                  className="p-3 rounded-4"
                  style={{ backgroundColor: "white" }}
                >
                  <Link
                    to={"/components"}
                    className="text-decoration-none text-dark"
                  >
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/chargerAd.jpg"
                        alt=""
                        className="img-fluid rounded-4"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Battery</h5>
                  </Link>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div
                  className="p-3 rounded-4"
                  style={{ backgroundColor: "white" }}
                >
                  <Link
                    to={"/components"}
                    className="text-decoration-none text-dark"
                  >
                    <div>
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/displayAd.jpg"
                        alt=""
                        className="img-fluid rounded-4"
                      />
                    </div>
                    <h5 className="poppins-medium mt-3 text-center">Display</h5>
                  </Link>
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div className="d-flex justify-content-center align-items-center py-4">
            <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
          </div>
        </div>

        {/* company logos */}
        <div className="container mt-5 pt-lg-5">
          <Splide
            className="flex align-items-center"
            extensions={{ AutoScroll }}
            options={{
              type: "loop",
              autoScroll: {
                speed: 1,
                pauseOnHover: false,
              },
              perPage: 6,
              drag: "free",
              arrows: false,
              pagination: false,
              pauseOnHover: false,
              gap: "3em",
              padding: "1em",
              breakpoints: {
                640: {
                  perPage: 3,
                  gap: "1em",
                },
              },
            }}
          >
            <SplideSlide className="h-100">
              <div>
                <img src={cst} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={shimano} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kenli} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={kmc} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={maxxis} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={wtb} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={rockshox} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={prowheel} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={neco} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={ralson} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sunrace} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={microshift} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={sram} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
            <SplideSlide className="h-100">
              <div>
                <img src={schwalbe} alt="" className="img-fluid" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        {/* why choose us */}
        <div className="container mt-5 pt-lg-5">
          <h2 className="poppins-medium mb-3 mb-lg-5">Why Choose Us?</h2>
          <div className="d-flex flex-wrap justify-content-between text-center w-100 mb-3 mb-lg-5 why-choose-us">
            <div className="flex-item">
              <div className="d-flex justify-content-center mb-3">
                <img
                  className="img-fluid"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/production.png"
                ></img>
              </div>

              <p className="m-0 poppins-medium">
                500,000<br></br>Production Capacity
              </p>
            </div>
            <div className="flex-item">
              <div className="d-flex justify-content-center mb-3">
                <img
                  className="img-fluid"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/component.png"
                ></img>
              </div>
              <p className="m-0 poppins-medium">
                400,000&nbsp;Component Capacity
              </p>
            </div>
            <div className="flex-item">
              <div className="d-flex justify-content-center mb-3">
                <img
                  className="img-fluid"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/cost.png"
                ></img>
              </div>
              <p className="m-0 poppins-medium">
                87%<br></br>Cost Benefits
              </p>
            </div>
            <div className="flex-item">
              <div className="d-flex justify-content-center mb-3">
                <img
                  className="img-fluid"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/iot.png"
                ></img>
              </div>
              <p className="m-0 poppins-medium">
                IOT enabled<br></br>solutions
              </p>
            </div>
          </div>
          <div className="d-flex flex-wrap flex-wrap-2 justify-content-between text-center w-100 why-choose-us">
            <div className="flex-item">
              <div className="d-flex justify-content-center mb-3">
                <img
                  className="img-fluid"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/certificate.png"
                ></img>
              </div>

              <p className="mt-2 poppins-medium">
                Europe<br></br>Certifications
              </p>
            </div>
            <div className="flex-item">
              <div className="d-flex justify-content-center mb-3">
                <img
                  className="img-fluid"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/in-stock.png"
                ></img>
              </div>
              <p className="mt-2 poppins-medium">
                Ready stock<br></br>available
              </p>
            </div>
            <div className="flex-item">
              <div className="d-flex justify-content-center mb-3">
                <img
                  className="img-fluid"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/automation.png"
                ></img>
              </div>
              <p className="mt-2 poppins-medium">
                All components<br></br>inhouse production
              </p>
            </div>
            <div className="flex-item">
              <div className="d-flex justify-content-center mb-3">
                <img
                  className="img-fluid"
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/container.png"
                ></img>
              </div>
              <p className="mt-2 poppins-medium">
                Legal Anti-dumping<br></br>solution
              </p>
            </div>
          </div>
        </div>
        {/* range of e-bikes */}
        <div className="container mt-5 pt-lg-5 ">
          <h2 className="poppins-medium mb-3 mb-lg-4">Range of E-Bikes</h2>
          <div className="range-ebikes">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center text-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    pagination: "false",
                    perPage: 1,
                    perMove: 1,
                    gap: "2em",
                    padding: "0em",
                    arrows: false,
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Mountain+bike.png"
                      alt=""
                      className="w-75"
                    />
                  </div>
                  <div className="text-start col col-md-6">
                    <h4 className="poppins-medium">Mountain E-Bikes</h4>
                    <p className="ecycle-range-car-content">
                      We're not just a mountain E-bike manufacturer; we're
                      trailblazers in off-road adventure. Explore a range of
                      e-bikes meticulously crafted for rugged terrains and
                      thrilling rides.
                    </p>
                    <div
                      className="col col-md-3 text-center rounded-3 py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/e-bikes/mountain"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Foldable+Fat+tyre.png"
                      alt=""
                      className="w-75"
                    />
                  </div>
                  <div className="text-start col col-md-6">
                    <h4 className="poppins-medium">
                      Folding & Fat Tyre E-Bikes
                    </h4>
                    <p className="ecycle-range-car-content">
                      As a top-tier folding eBike manufacturer, DYNEM brings you
                      the ultimate blend of convenience and style. Our folding
                      e-bikes are perfect for city explorers and jet-setters.
                    </p>
                    <div
                      className="col col-md-3 text-center rounded-3 py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/e-bikes/folding-fat-tyre"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/City+Bike.png"
                      alt=""
                      className="w-75"
                    />
                  </div>
                  <div className="text-start col col-md-6">
                    <h4 className="poppins-medium">City E-Bikes</h4>
                    <p className="ecycle-range-car-content">
                      As a premier city bike manufacturer, DYNEM redefines urban
                      commuting with sleek, efficient, and stylish rides. Our
                      city bikes blend cutting-edge technology with modern
                      design, ensuring you glide through traffic with ease.
                    </p>
                    <div
                      className="col col-md-3 text-center rounded-3 py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/e-bikes/city"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Delivery+bike.png"
                      alt=""
                      className="w-75"
                    />
                  </div>
                  <div className="text-start col col-md-6">
                    <h4 className="poppins-medium">
                      <h4 className="poppins-medium">Delivery E-Bikes</h4>
                    </h4>
                    <p className="ecycle-range-car-content">
                      As a leading delivery bike manufacturer, DYNEM delivers
                      top-tier performance and reliability for every delivery.
                      Our e-bikes are designed for the demands of urban
                      logistics, powerful motors, and ample cargo space.
                    </p>
                    <div
                      className="col col-md-3 text-center rounded-3 py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/e-bikes/delivery"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* range of dts */}
        <div className="container mt-5 pt-lg-5">
          <h2 className="poppins-medium mb-3 mb-lg-4">Range of Components</h2>
          <div className="mx-lg-5">
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center text-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                focus: "center",
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    pagination: "false",
                    perPage: 1,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Controlerrrr.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-start col col-md-6">
                    <h4 className="poppins-medium">Precision Controllers</h4>
                    <p className="component-card-content">
                      Controllers in an e-bike employs sensors and algorithms to
                      adjust power delivery seamlessly and its intelligent
                      system enhances performance and makes the e-bike more
                      intuitive and user-friendly.
                    </p>
                    <div
                      className="col col-md-3 text-center rounded-3 py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/components"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Chargerrrr.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-start col col-md-6">
                    <h4 className="poppins-medium">
                      High Performance Chargers
                    </h4>
                    <p className="component-card-content">
                      The chargers come in a range of customisable options that
                      cater to preferences, based on global compatibility,
                      portability or extended power flexibility.
                    </p>
                    <div
                      className="col col-md-3 text-center rounded-3 py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/components"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Displayyyyy.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-start col col-md-6">
                    <h4 className="poppins-medium">Multifunctional Displays</h4>
                    <p className="component-card-content">
                      These LCD displays offer real-time data like speed and
                      battery which empowers riders to monitor and adjust their
                      riding modes.
                    </p>
                    <div
                      className="col col-md-3 text-center rounded-3 py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/components"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/Baterryyyyy.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-start col col-md-6">
                    <h4 className="poppins-medium">
                      <h4 className="poppins-medium">Long Lasting Batteries</h4>
                    </h4>
                    <p className="component-card-content">
                      This electric system stores energy and powers the electric
                      motor. This technology has been designed to ensure
                      consistent performance, longer battery life, and optimal
                      power delivery, enhancing the overall riding experience.
                    </p>
                    <div
                      className="col col-md-3 text-center rounded-3 py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/components"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pt-0 p-lg-0 d-flex align-items-center ecycle-range-card">
                  <div className="col col-md-6">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/motorrrr.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="text-start col col-md-6">
                    <h4 className="poppins-medium">
                      <h4 className="poppins-medium">Efficient Motors</h4>
                    </h4>
                    <p className="component-card-content">
                      Our versatile and innovative motors have been designed to
                      offer reliable and accurate performance, ensuring optimal
                      utility in various applications.
                    </p>
                    <div
                      className="col col-md-3 text-center rounded-3 py-2 mt-2"
                      style={{
                        backgroundColor: "black",
                        minHeight: "max-content",
                      }}
                    >
                      <Link
                        className="text-light text-decoration-none poppins-medium"
                        to={"/components"}
                      >
                        Explore
                      </Link>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* certifications */}
        <div className="container mt-5 pt-lg-5 mb-5">
          <h2 className="poppins-medium mb-3 mb-lg-4">Certifications</h2>
          <img
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/certifications.jpg"
            alt="certifications"
            className="w-100 h-75"
          ></img>
        </div>
        <div className="container mt-5 pt-lg-5">
          <h2 className="poppins-medium mb-3 mb-lg-4">
            Switching production, easier than ever before
          </h2>
          <div className="mx-lg-5 switching">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "3em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "0em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/1.png"
                      alt=""
                      className="img-fluid w-50"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Mass Production</h5>
                    <p className="m-0">
                      Alignment on production post order finalization
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/2.png"
                      alt=""
                      className="img-fluid w-50"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Sampling process</h5>
                    <p className="m-0">
                      Also enabling check on anti dumping tariff validation
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/3.png"
                      alt=""
                      className="img-fluid w-50"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">
                      Explore our product offering
                    </h5>
                    <p className="m-0">
                      We will match your specs along with offering a complete
                      Localized offering (country of origin)
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/4.png"
                      alt=""
                      className="img-fluid w-50"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Arranging factory visit</h5>
                    <p className="m-0">
                      to provide you deep insights on the state of the art
                      machinery and manufacturing process
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/5.png"
                      alt=""
                      className="img-fluid w-50"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Product Finalization</h5>
                    <p className="m-0">Post sampling and batch testing</p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/6.png"
                      alt=""
                      className="img-fluid w-50"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Support in After-Sales</h5>
                    <p className="m-0">
                      Along with and spares planning - Europe and USA
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/7.png"
                      alt=""
                      className="img-fluid w-50"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Extend Logistics Support</h5>
                    <p className="m-0">
                      To enable the best shipping solution with our extended
                      partner network
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div className="p-4 pb-0 pt-0 p-lg-0">
                  <div className="d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/8.png"
                      alt=""
                      className="img-fluid w-50"
                    />
                  </div>
                  <div className="text-center text-lg-start">
                    <h5 className="poppins-medium">Send your requirements</h5>
                    <p className="m-0">(bike images and specifications)</p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
        </div>
        {/* amigo */}
        <div className=" mt-5 pt-lg-5">
          <h2 className="poppins-medium mb-3 mb-lg-5 container">
            Amigo - one app | limitless possibilities
          </h2>
          <div className="">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                start: 0,
                drag: true,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "1.5em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                focus: "center",
                perPage: 1.5,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    autoplay: false,
                    arrows: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "0em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100 rounded-4">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_1.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100 rounded-4">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_2.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100 rounded-4">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_3.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100 rounded-4">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_4.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100 rounded-4">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_5.png"
                  className="w-100"
                ></img>
              </SplideSlide>
              <SplideSlide className="h-100 rounded-4">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/amigo_6.png"
                  className="w-100"
                ></img>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
        </div>
        {/* testimonials */}
        <div className=" mt-5 pt-lg-5 container testimonials">
          <h2 className="poppins-medium mb-3 mb-lg-5 container">
            Testimonials
          </h2>
          <div className="mt-5 mt-lg-0">
            <Splide
              className="mb-lg-0 p-0 align-items-center"
              options={{
                arrows: true,
                start: 0,
                drag: false,
                type: "loop",
                pagination: true,
                autoplay: true,
                gap: "1.5em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 1,
                perMove: 1,
                speed: 1000,
                interval: 4000,
                breakpoints: {
                  700: {
                    drag: true,
                    arrows: false,
                    autoplay: false,
                    perPage: 1,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100 rounded-4">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial1.png"></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        “One of the best e-bike manufacturer in the market! Have
                        consistently gotten e-bikes customized to my liking and
                        they never disappoint, 11/10 recommended!..........”
                      </h4>
                      <p className="poppins-medium mb-0">James Mill</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100 rounded-4">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial2.png"></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        Switching to Dynem was a game-changer for us! The entire
                        process, from sourcing to delivery, was seamless. Plus,
                        the savings we achieved by moving our manufacturing to
                        India were substantial. Highly recommend!"
                      </h4>
                      <p className="poppins-medium mb-0">Jake Severisson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100 rounded-4">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial3.png"></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "Working with Dynem has been nothing short of excellent.
                        The transition from China to India was incredibly
                        smooth, and the cost savings we realized were
                        significant. Great partner for ebike manufacturing!"
                      </h4>
                      <p className="poppins-medium mb-0">Carolina Spike</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100 rounded-4">
                <div className="d-flex p-lg-4" style={{ gap: "2em" }}>
                  <div className="w-25 d-none d-lg-block">
                    <img src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/testimonial4.png"></img>
                  </div>
                  <div className="position relative">
                    <div className="position-absolute inverted-comma d-lg-none">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/inverted.png"
                        className="img-fluid"
                      ></img>
                    </div>
                    <div className="ps-5 ps-lg-0">
                      <h4 className="poppins-medium text-uppercase mb-4">
                        "Dynem made our shift to Indian manufacturing
                        effortless. Their attention to detail and commitment to
                        quality were impressive, and the cost benefits were
                        immediate. We’re very satisfied with the move!
                      </h4>
                      <p className="poppins-medium mb-0">Kevin Spearson</p>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* news */}
        <div className="news">
          <div className="container mt-5 py-4 py-lg-5">
            <h2 className="poppins-medium mb-3 mb-lg-5">In the news</h2>
            <Splide
              className="mb-2 mb-lg-0 p-0 align-items-center"
              options={{
                arrows: false,
                drag: false,
                type: "slide",
                pagination: false,
                autoplay: false,
                perMove: 1,
                gap: "2em",
                padding: "0em",
                pauseOnHover: true,
                resetProgress: false,
                perPage: 4,
                speed: 1000,
                interval: 3000,
                breakpoints: {
                  700: {
                    drag: true,
                    perPage: 1.3,
                    arrows: false,
                    perMove: 1,
                    gap: "1em",
                    padding: "0em",
                    pauseOnHover: false,
                    resetProgress: false,
                    focus: 0,
                    start: 0,
                    speed: 1000,
                    interval: 3000,
                  },
                },
              }}
              muted={true}
            >
              <SplideSlide className="h-100">
                <div
                  className="p-3 rounded-4"
                  style={{ backgroundColor: "white" }}
                >
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+1.png"
                      alt=""
                      className="img-fluid rounded-4"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/kxan.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM begins construction of World's Biggest Integrated
                    Electric Cycle Gigafactory.....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center rounded-3 py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.kxan.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div
                  className="p-3 rounded-4"
                  style={{ backgroundColor: "white" }}
                >
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+2.png"
                      alt=""
                      className="img-fluid rounded-4"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/ktla.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    India’s DYNEM to Establish World’s Largest Integrated E-Bike
                    Gigafactory
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center rounded-3 py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://fox59.com/business/press-releases/cision/20240509IO08933/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india/"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div
                  className="p-3 rounded-4"
                  style={{ backgroundColor: "white" }}
                >
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+3.png"
                      alt=""
                      className="img-fluid rounded-4"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/benzinga.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM Breaks Ground on World’s Largest Integrated Electric
                    Cycle Gigafactory ...
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center rounded-3 py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide className="h-100">
                <div
                  className="p-3 rounded-4"
                  style={{ backgroundColor: "white" }}
                >
                  <div>
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/News+4.png"
                      alt=""
                      className="img-fluid rounded-4"
                    />
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/fox8.png"
                    className="img-fluid mt-2"
                  ></img>
                  <p className="my-3 news-heading poppins-medium">
                    DYNEM Strengthens India’s Manufacturing Prowess: World’s
                    Biggest .....
                  </p>
                  <small>May 09, 2024</small>
                  <div
                    className="w-100 text-center rounded-3 py-2 mt-2"
                    style={{
                      backgroundColor: "black",
                      minHeight: "max-content",
                    }}
                  >
                    <a
                      className="text-light text-decoration-none"
                      href="https://www.benzinga.com/pressreleases/24/05/n38730936/dynem-begins-construction-of-worlds-biggest-integrated-electric-cycle-gigafactory-in-india"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
        {/* FAQs */}
        <div className="container mt-5 pt-lg-5">
          <div className="d-flex faq-landing">
            <div className="faq-landing-1">
              <h2 className="poppins-medium mb-2 mb-lg-4">
                Frequently asked questions
              </h2>
              <p className="mb-lg-5">
                Got questions? We’ve already got answers. It’s like we can hear
                you thinking.
              </p>
              <h4 className="poppins-medium mb-2">Did we miss something?</h4>
              <p className="mb-lg-5">
                Get on a one on one call with our experts.
              </p>
              <div className="req-call">
                <a
                  href="tel:"
                  className="btn btn-dark rounded-3 px-5 d-flex justify-content-between"
                  style={{ width: "fit-content" }}
                >
                  Request a Call
                </a>
              </div>
            </div>
            <div className="accordion faq-landing-2" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What makes DYNEM different from other e-bike manufacturers?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body poppins-thin">
                    <p>
                      DYNEM stands out for its cutting-edge innovation,
                      eco-friendly practices, and extensive customization
                      options. Our state-of-the-art gigafactory, global
                      compliance, and expert team ensure top-notch quality and
                      seamless support from production to after-sales, leaving
                      you with nothing but peace of mind.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Can I customise my e-bike with DYNEM?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      Absolutely! DYNEM offers extensive customization options,
                      allowing you to tailor your e-bike to your specific style
                      and needs. Our OEM service lets you customise branding,
                      specifications, and features to match your preferences.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    How many e-bikes does DYNEM produce annually?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM has the capacity to produce and customise 500,000
                      e-bikes annually. Our efficient production processes and
                      world-class infrastructure enable us to meet growing
                      global demand.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    What are the benefits of DYNEM's anti-dumping solutions?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM's legal anti-dumping solutions allow importers to
                      save up to 87% in taxes. Our products comply with global
                      regulations, ensuring a level playing field without any
                      anti-dumping taxes. This makes DYNEM a cost-effective and
                      compliant choice for importers worldwide.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    How does DYNEM support sustainability?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM is committed to sustainability through eco-friendly
                      practices and innovative technology. Our e-bikes are
                      designed to reduce carbon footprints and promote a greener
                      future, aligning with our vision of sustainable mobility.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    What kind of after-sales support does DYNEM provide?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      DYNEM prioritises customer satisfaction with dedicated
                      after-sales support. From the initial purchase to
                      long-term maintenance, our team is committed to providing
                      seamless and reliable assistance, ensuring a smooth and
                      satisfying experience for our customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4 mt-lg-5">
          <Modal className="primary-button fw-bold px-4 py-2 rounded-3" />
        </div>
        {/* contact us */}
        <div className="mt-5 py-lg-5 py-4" style={{ backgroundColor: "black" }}>
          <div className="container">
            <div className="d-flex mb-3 mb-lg-0" style={{ gap: "4em" }}>
              <div className="d-none d-lg-block w-50">
                <div className="row">
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                        className="w-75 p-3 rounded-4"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-end">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                        className="w-100 p-3 rounded-4"
                      ></img>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="d-flex justify-content-start mt-5">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                        className="w-100 p-3 rounded-4"
                      ></img>
                    </div>
                    <div className="d-flex justify-content-start">
                      <img
                        src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                        className="w-75 p-3 rounded-4"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <h2 className="poppins-medium mb-4 text-light">
                  Let’s Connect
                </h2>
                <form className="contact-us-landing" onSubmit={submit}>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Full Name*
                      </label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required
                        class="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Country*
                      </label>
                      <input
                        type="text"
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                        required
                        class="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                    <div class="w-50">
                      <label
                        for="exampleInputPassword1"
                        class="form-label text-light"
                      >
                        Contact No*
                      </label>
                      <PhoneInput
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        required
                        defaultCountry="IN"
                        className="form-control w-100"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Work Email*
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                        class="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>

                  <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Organisation*
                      </label>
                      <input
                        type="text"
                        value={companyName}
                        required
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        class="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div className="w-50">
                      <label
                        for="exampleInputEmail1"
                        className="form-label text-light"
                      >
                        Designation*
                      </label>
                      <input
                        type="text"
                        required
                        value={designation}
                        onChange={(e) => {
                          setDesignation(e.target.value);
                        }}
                        class="form-control"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Post Your Enquiry*
                    </label>
                    <textarea
                      class="form-control"
                      required
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={enquiry}
                      onChange={(e) => {
                        setEnquiry(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-light w-100 poppins-medium"
                  >
                    Get Quote
                  </button>
                </form>
              </div>
            </div>
            <div className="d-lg-none mb-5">
              <h2 className="poppins-medium mb-4 text-light">Let’s Connect</h2>
              <form className="contact-us-landing" onSubmit={submit}>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Full Name*
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      required
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Country*
                    </label>
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      required
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex" style={{ gap: "1em" }}>
                  <div class="w-50">
                    <label
                      for="exampleInputPassword1"
                      class="form-label text-light"
                    >
                      Contact No*
                    </label>
                    <PhoneInput
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      required
                      defaultCountry="IN"
                      className="form-control w-100"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Work Email*
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>

                <div class="mb-4 d-flex" style={{ gap: "1em" }}>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Organisation*
                    </label>
                    <input
                      type="text"
                      value={companyName}
                      required
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                      }}
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="w-50">
                    <label
                      for="exampleInputEmail1"
                      className="form-label text-light"
                    >
                      Designation*
                    </label>
                    <input
                      type="text"
                      required
                      value={designation}
                      onChange={(e) => {
                        setDesignation(e.target.value);
                      }}
                      class="form-control"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    className="form-label text-light"
                  >
                    Post Your Enquiry*
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    required
                    rows="3"
                    value={enquiry}
                    onChange={(e) => {
                      setEnquiry(e.target.value);
                    }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="btn btn-light w-100 poppins-medium"
                >
                  Get Quote
                </button>
              </form>
            </div>
            <div className="row p-2 d-lg-none">
              <div className="col-6 p-0">
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid1.png"
                    className="w-75 p-2 rounded-4"
                  ></img>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid5.png"
                    className="w-100 p-2 rounded-4"
                  ></img>
                </div>
              </div>
              <div className="col-6 p-0">
                <div className="d-flex justify-content-start mt-5">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid2.png"
                    className="w-100 p-2 rounded-4"
                  ></img>
                </div>
                <div className="d-flex justify-content-start">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/landingPages/get-quote/grid4.png"
                    className="w-75 p-2 rounded-4"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GetQuoteLanding;
