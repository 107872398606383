import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./views/HomePage";
import DTS from "./views/DTS";
import EBikes from "./views/EBikes";
import ListingPage from "./views/ListingPage";
import AboutUs from "./views/AboutUs";
import ContactUs from "./views/ContactUs";
import ThankYouPage from "./views/ThankYouPage";
import Tech from "./views/Tech";
import Assurance from "./views/Assurance";
import AntiDumping from "./views/AntiDumping";
import BuildCustomBike from "./views/BuildCustomBike";
import Blogs from "./views/Blogs";
import PrivacyPolicy from "./views/PrivacyPolicy";
import LandingPage1 from "./views/LandingPage1";
import CustomBike from "./components/CustomBike";
import Blog from "./views/Blog";
import GetQuoteLanding from "./views/GetQuoteLanding";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/custom-bike" element={<BuildCustomBike />} />
        <Route path="/e-bikes" element={<EBikes />} />
        <Route path="/e-bikes/:name" element={<ListingPage />} />
        <Route path="/components" element={<DTS />} />
        <Route path="/components/:name" element={<ListingPage />} />
        <Route path="/tech" element={<Tech />} />
        {/* <Route path="/assurance" element={<Assurance />} /> */}
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<Blog />} />
        <Route path="/legal-anti-dumping-solution" element={<AntiDumping />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/details-submitted" element={<ThankYouPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/get-quote" element={<GetQuoteLanding />} />
      </Routes>
    </Router>
  );
}

export default App;
