import React, { useEffect, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import Meta from "../components/Meta";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../css/blogs.css";

const Blog = () => {
  const { slug } = useParams();
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [blogPost, setBlogPost] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!slug) return;
      const hygraph = new GraphQLClient(
        "https://ap-south-1.cdn.hygraph.com/content/clyn0n8l500x208vystxkfz7b/master"
      );
      const QUERY2 = gql`
        query GetBlogPost($slug: String!) {
          posts(where: { slug: $slug }) {
            coverImage {
              url
            }
            date
            slug
            seoOverride {
              title
              description
            }
            title
            content {
              html
              markdown
              raw
              text
            }
          }
        }
      `;
      try {
        const { posts } = await hygraph.request(QUERY2, { slug });
        setBlogPost(posts[0]);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    const fetchData = async () => {
      if (!slug) return;
      const hygraph = new GraphQLClient(
        "https://ap-south-1.cdn.hygraph.com/content/clyn0n8l500x208vystxkfz7b/master"
      );
      const QUERY1 = gql`
        {
          posts(orderBy: date_DESC) {
            title
            slug
            coverImage {
              url
            }
            excerpt
          }
        }
      `;
      try {
        const { posts } = await hygraph.request(QUERY1);
        console.log(posts);
        setBlogPosts(posts);
      } catch (error) {
        console.error("Error fetching blog post:", error);
      }
    };

    fetchData();
  }, [slug]);

  const renderBlogContent = () => {
    return parse(blogPost.content.html, {
      replace: (domNode) => {
        if (domNode.name && domNode.name === "img") {
          return <img {...domNode.attribs} className="img-fluid" />;
        }
        return undefined;
      },
    });
  };

  return (
    <>
      <>
        <Meta
          title={blogPost.seoOverride && blogPost.seoOverride.title}
          description={blogPost.seoOverride && blogPost.seoOverride.description}
          cannonicalTag={`https://www.emotorad.com/blogs/${slug}`}
        />
        <Navbar />
        <div className="container mt-lg-5 mt-4">
          {blogPost.content && blogPosts && (
            <div>
              <div className="d-flex justify-space-between mt-3">
                <div className="p-lg-3">
                  <h1 className="">{blogPost.title}</h1>
                  <p className="fw-bold  font-mon">
                    <span className="pe-2 text-muted">
                      {weekday[new Date(blogPost.date).getDay()]},
                    </span>
                    <span className="pe-2 text-muted">
                      {new Date(blogPost.date).getDate()} {""}
                      {months[new Date(blogPost.date).getMonth()]}
                    </span>
                    <span className="pe-2 text-muted">
                      {new Date(blogPost.date).getFullYear()}
                    </span>
                  </p>
                  <img
                    style={{
                      borderRadius: "10px",
                    }}
                    src={blogPost.coverImage && blogPost.coverImage.url}
                    className="img-fluid  d-block mx-auto"
                    alt=""
                  />
                  <div id="content" className="blog-content mt-4">
                    {renderBlogContent()}
                  </div>
                </div>
                {/* <div className="p-lg-4 d-none d-lg-block sidebar">
                  <h4 className="mb-2 text-center">Latest Articles</h4>
                  <table className="text-center w-75 mx-auto">
                    {blogPosts.slice(0, 5).map((post, i) => (
                      <tr>
                        <td>
                          <Link
                            to={`/blogs/${post.slug}`}
                            className="text-decoration-none text-primary"
                          >
                            {post.title}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div> */}
              </div>
              <div className="mb-4">
                <hr className="mb-4 mb-lg-5"></hr>
                <h4 className="mb-3">
                  Other Related Articles that might interest you
                </h4>
                <div
                  className="d-flex flex-column flex-lg-row"
                  style={{ gap: "1.2em" }}
                >
                  {blogPosts.slice(0, 3).map((post, i) => (
                    <div className="related-blogs">
                      <Link
                        to={`/blogs/${post.slug}`}
                        className="text-decoration-none text-dark"
                      >
                        <img
                          src={post.coverImage.url}
                          alt=""
                          className="img-fluid rounded-2"
                        />
                        <h5 className="my-2">{post.title}</h5>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </>
    </>
  );
};

export default Blog;
