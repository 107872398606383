import React, { useEffect, useState } from "react";
import FrameSelection from "./FrameSelection.jsx";
import MotorSelection from "./MotorSelection.jsx";
import "../css/customBike.css";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import DerailleurSelection from "./DerailleurSelection.jsx";
import BatterySelection from "./BatterySelection.jsx";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import Meta from "../components/Meta.jsx";
import DisplaySelection from "./DisplaySelection.jsx";

const BuildCustomBike = () => {
  const frames = [
    {
      id: 1,
      name: "C076 Frame",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/c076+frame.png",
    },
    {
      id: 2,
      name: "EMX Frame",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/emx+frame.png",
    },
    {
      id: 3,
      name: "F033 Frame",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/F033+frame.png",
    },
    {
      id: 4,
      name: "F043 Frame",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/f043+frame.png",
    },
    {
      id: 5,
      name: "F052 Frame",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/F052+frame.png",
    },
    {
      id: 6,
      name: "M062 Frame",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/m062+frame.png",
    },
    {
      id: 7,
      name: "T-REX Frame",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/trex+frame.png",
    },
    {
      id: 8,
      name: "X1 Frame",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/x1+frame.png",
    },
    {
      id: 9,
      name: "X2 Frame",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/x2+frame.png",
    },
  ];
  const motors = [
    {
      id: 1,
      name: "250W",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/Motor2.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/c076+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/emx+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/F033+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/f043+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/F052+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/m062+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/trex+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/x1+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/x2+motor.png",
      ],
    },
    {
      id: 2,
      name: "350W",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/Motor2.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/c076+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/emx+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/F033+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/f043+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/F052+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/m062+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/trex+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/x1+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/x2+motor.png",
      ],
    },
    {
      id: 3,
      name: "500W",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/Motor2.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/c076+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/emx+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/F033+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/f043+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/F052+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/m062+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/trex+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/x1+motor.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/motor/x2+motor.png",
      ],
    },
  ];
  const derailleurs = [
    {
      id: 1,
      name: "7 Speed",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/7+speed.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/c076+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/emx+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/F033+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/f043+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/F052+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/m062+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/trex+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/x1+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/x2+der.png",
      ],
    },
    {
      id: 2,
      name: "8 Speed",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/7+speed.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/c076+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/emx+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/F033+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/f043+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/F052+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/m062+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/trex+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/x1+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/x2+der.png",
      ],
    },
    {
      id: 3,
      name: "21 Speed",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/21+speed.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/c076+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/emx+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/F033+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/f043+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/F052+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/m062+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/trex+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/x1+der.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/derailleur/x2+der.png",
      ],
    },
  ];
  const batteries = [
    {
      id: 1,
      name: "5.6AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery1.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
    {
      id: 2,
      name: "7.65AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery1.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
    {
      id: 3,
      name: "10.2AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery1.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
    {
      id: 4,
      name: "13AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery1.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
    {
      id: 5,
      name: "16AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery1.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
    {
      id: 6,
      name: "5.6AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery2.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
    {
      id: 7,
      name: "7.65AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery2.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
    {
      id: 8,
      name: "10.2AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery2.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
    {
      id: 9,
      name: "13AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery2.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
    {
      id: 10,
      name: "16AH",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/battery2.png",
      bikes: [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/c076+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/emx+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F033+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/f043+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/F052+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/m062+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/trex+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x1+battery.png",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/battery/x2+battery.png",
      ],
    },
  ];
  const displays = [
    {
      id: 1,
      name: "P9 LCD Display",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+1.png",
      bikeImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+1.png",
    },
    {
      id: 2,
      name: "M6H LCD Display",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+2.png",
      bikeImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+2.png",
    },
    {
      id: 3,
      name: "M5 LCD Display",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+3.png",
      bikeImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+3.png",
    },
    {
      id: 4,
      name: "M7C Display",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+5.png",
      bikeImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+5.png",
    },
    {
      id: 5,
      name: "S866 Display ",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+6.png",
      bikeImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/display/Display+6.png",
    },
  ];
  const [step, setStep] = useState(0);
  const [selectedFrame, setSelectedFrame] = useState(frames[0]);
  const [prevFrame, setPrevFrame] = useState(null);
  const [selectedMotor, setSelectedMotor] = useState(motors[0]);
  const [selectedDerailleur, setSelectedDerailleur] = useState(derailleurs[0]);
  const [selectedBattery, setSelectedBattery] = useState(batteries[0]);
  const [selectedDisplay, setSelectedDisplay] = useState(displays[0]);
  const [build, setBuild] = useState({
    frame: null,
    motor: null,
    derailleur: null,
    battery: null,
    display: null,
  });

  useEffect(() => {
    if (!selectedFrame) {
      setSelectedFrame({
        id: 1,
        name: "C076 Frame",
        image:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/custom+bike/frame/c076+frame.png",
      });
    }
  }, [selectedFrame]);

  const handlePrevious = () => setStep(step - 1);

  const selectBatteryBasedOnFrame = (frame) => {
    if (frame.name === "C076 Frame") {
      setSelectedBattery(batteries[0]);
    } else {
      setSelectedBattery(batteries[5]);
    }
  };

  useEffect(() => {
    if (selectedFrame && selectedFrame !== prevFrame) {
      if (selectedFrame.name === "C076 Frame") {
        setSelectedBattery(batteries[0]);
      } else {
        setSelectedBattery(batteries[5]);
      }
      setPrevFrame(selectedFrame); // Update previous frame
    }
  }, [selectedFrame, prevFrame]);

  const handleAddToBuild = () => {
    if (selectedFrame) {
      setBuild({ ...build, frame: selectedFrame });
    }
    if (selectedMotor) {
      setBuild({ ...build, motor: selectedMotor });
    }
    if (selectedDerailleur) {
      setBuild({ ...build, derailleur: selectedDerailleur });
    }
    if (selectedBattery) {
      setBuild({ ...build, battery: selectedBattery });
    }
    setStep(step + 1);
  };

  const steps = [
    { name: "Frame", component: FrameSelection },
    { name: "Motor", component: MotorSelection },
    { name: "Derailleur", component: DerailleurSelection },
    { name: "Battery", component: BatterySelection },
    { name: "Display", component: DisplaySelection },
  ];

  const StepComponent = steps[step]?.component;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [designation, setDesignation] = useState("");

  const navigate = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    const customBikeData = {
      frame: { name: selectedFrame.name, image: selectedFrame.image },
      motor: { name: selectedMotor.name, image: selectedMotor.image },
      derailleur: {
        name: selectedDerailleur.name,
        image: selectedDerailleur.image,
      },
      battery: { name: selectedBattery.name, image: selectedBattery.image },
      display: { name: selectedDisplay.name, image: selectedDisplay.image },
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/form/request-a-quote",
      {
        name,
        email,
        phoneNumber,
        companyName,
        designation,
        customBikeData,
      },
      config
    );
    if (data) {
      navigate("/details-submitted");
    }
  };
  return (
    <>
      <Meta cannonicalTag={"https://dynem.in/custom-bike"} />
      <Navbar />
      <div className="pt-4 pb-2" style={{ backgroundColor: "#eeeeee" }}>
        {/* <div>
          {Object.entries(build).map(([key, value]) =>
            value ? <div key={key}>{`${key}: ${value.name}`}</div> : null
          )}
        </div> */}
        {step === 4 ? (
          <>
            <div
              className="d-flex justify-content-center display-final px-3"
              style={{ gap: "2em" }}
            >
              <div
                className="listing-category-cards px-3 px-lg-5 py-2 py-lg-3 mt-lg-5 text-center"
                style={{ height: "fit-content" }}
              >
                <h3>Your Selections</h3>
                <div className="mt-3">
                  <p>
                    <b>Frame:&nbsp;</b>
                    {selectedFrame.name}
                  </p>
                  <p>
                    <b>Motor:&nbsp;</b>
                    {selectedMotor.name}
                  </p>
                  <p>
                    <b>Derailleur:&nbsp;</b>
                    {selectedDerailleur.name}
                  </p>
                  <p className="">
                    <b>Battery:&nbsp;</b>
                    {selectedBattery.name}
                  </p>
                  <p className="m-0">
                    <b>Display:&nbsp;</b>
                    {selectedDisplay.name}
                  </p>
                </div>
              </div>
              <img
                src={selectedBattery.bikes[selectedFrame.id - 1]}
                className="final-custom-image"
                alt="final custom img"
              />
            </div>
            <div
              className="d-flex justify-content-center my-4"
              style={{ gap: "1em" }}
            >
              {step > 0 && (
                <button
                  onClick={handlePrevious}
                  className="text-decoration-none secondary-button fw-bold px-4 py-2"
                >
                  Previous
                </button>
              )}
              <button
                type="button"
                className="primary-button fw-bold"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
              >
                REQUEST A QUOTE
              </button>

              <div
                class="modal fade"
                id="exampleModal2"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content p-3 pt-2 rounded-0">
                    <div class="modal-header py-2">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <form
                        className=" d-flex justify-content-between align-content-center flex-column p-2 p-lg-4 pt-0"
                        onSubmit={submit}
                      >
                        <h5 className="mb-3 text-center text-black fw-bold">
                          REQUEST A QUOTE
                        </h5>
                        <input
                          type="text"
                          className="form-control input-gifting mb-3 w-100"
                          required
                          value={name}
                          autoComplete="off"
                          style={{ background: "none" }}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Name"
                        />
                        <input
                          type="email"
                          placeholder="Email"
                          required
                          className="form-control input-gifting mb-3 w-100"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <PhoneInput
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          defaultCountry="IN"
                          className="form-control mb-3 w-100"
                        />
                        <input
                          type="text"
                          placeholder="Company Name"
                          className="form-control input-gifting mb-3 w-100"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                        <input
                          type="text"
                          placeholder="Designation"
                          className="form-control input-gifting mb-3 w-100"
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                        />
                        <button
                          data-bs-dismiss="modal"
                          type="submit"
                          className=" w-100 contact-us-submit p-2"
                        >
                          Submit
                        </button>
                        <small
                          className="text-center text-secondary mt-4"
                          style={{ fontSize: "0.7em" }}
                        >
                          By choosing to confirm, you consent to share your
                          personal information with Dynem. Terms and Conditions
                          applied.
                        </small>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <h4 className="text-center">Select {steps[step].name}</h4>
            {/* {selectedFrame && (
            <div className="d-flex justify-content-center mx-auto div-display">
              <img
                src={selectedFrame.image}
                alt={selectedFrame.name}
                className="img-fluid"
              />
            </div>
          )} */}
            <div className="d-flex justify-content-center mx-auto div-display">
              {steps[step].name === "Frame" ? (
                <img
                  src={selectedFrame.image}
                  alt={selectedFrame.name}
                  className="img-fluid"
                />
              ) : steps[step].name === "Motor" ? (
                <img
                  src={selectedMotor.bikes[selectedFrame.id - 1]}
                  alt={selectedMotor.name}
                  className="img-fluid"
                />
              ) : steps[step].name === "Derailleur" ? (
                <img
                  src={selectedDerailleur.bikes[selectedFrame.id - 1]}
                  alt={selectedMotor.name}
                  className="img-fluid"
                />
              ) : steps[step].name === "Battery" ? (
                <img
                  src={selectedBattery.bikes[selectedFrame.id - 1]}
                  alt={selectedMotor.name}
                  className="img-fluid"
                />
              ) : (
                <img
                  src={selectedDisplay.bikeImage}
                  alt={selectedMotor.name}
                  className="img-fluid"
                />
              )}
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ gap: "1em" }}
            >
              {step > 0 && (
                <button
                  onClick={handlePrevious}
                  className="text-decoration-none secondary-button fw-bold px-4 py-2"
                >
                  Previous
                </button>
              )}
              <button
                className="text-decoration-none primary-button fw-bold px-4 py-2"
                onClick={handleAddToBuild}
              >
                Add to Build
              </button>
            </div>
            {
              steps[step].name === "Frame" ? (
                <StepComponent
                  components={frames}
                  selected={selectedFrame}
                  onSelectComponent={setSelectedFrame}
                  onAddToBuild={handleAddToBuild}
                />
              ) : steps[step].name === "Motor" ? (
                <>
                  <StepComponent
                    components={motors}
                    selected={selectedMotor}
                    onSelectComponent={setSelectedMotor}
                    onAddToBuild={handleAddToBuild}
                  />
                </>
              ) : steps[step].name === "Derailleur" ? (
                <>
                  <StepComponent
                    components={derailleurs}
                    selected={selectedDerailleur}
                    onSelectComponent={setSelectedDerailleur}
                    onAddToBuild={handleAddToBuild}
                  />
                </>
              ) : steps[step].name === "Battery" ? (
                <>
                  <StepComponent
                    components={batteries} // Assuming derailleurs is defined
                    selected={selectedBattery} // Assuming selectedDerailleur is defined
                    onSelectComponent={setSelectedBattery} // Assuming setSelectedDerailleur is defined
                    onAddToBuild={handleAddToBuild}
                  />
                </>
              ) : (
                <>
                  <StepComponent
                    components={displays} // Assuming derailleurs is defined
                    selected={selectedDisplay} // Assuming selectedDerailleur is defined
                    onSelectComponent={setSelectedDisplay} // Assuming setSelectedDerailleur is defined
                    onAddToBuild={handleAddToBuild}
                  />
                </>
              )
              // : (
              //   // Default case if none of the conditions match
              //   <div>No matching step found</div>
            }
          </>
        )}
      </div>
      <div className="line-break"></div>
      <Footer />
    </>
  );
};

export default BuildCustomBike;
