import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/thankyou.css";
import Meta from "../components/Meta";

const ThankYouPage = () => {
  <Meta cannonicalTag={"https://dynem.in/details-submitted"} />;
  return (
    <>
      <div className="container">
        <div className="thankyou-div">
          <p className="thankyou">Thank you for getting in touch with us!</p>
          <br></br>
          <h4>We'll speak soon!</h4>
          <h6>
            In the meantime, why not find out more about us and our e-bikes.
            Alternatively, give us a call on +91 8686050590
          </h6>
          <Link to={"/"} className="go-back-link">
            Go Back
          </Link>
        </div>
      </div>
    </>
  );
};

export default ThankYouPage;
